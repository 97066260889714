import { FC, ReactNode } from "react";
import Link from "next/link";
import { useBreakpoints } from "@otrium/core";
import { BrandData } from "src/types/graphql.d";
import { Card } from "src/molecules/Card";
import { Carousel } from "src/molecules/Carousel";
import {
  RetroRevivalContainer,
  RetroRevivalWrapper,
} from "./RetroRevival.styled";
import { useImageHeight } from "src/hooks/useImageHeight";
import { SEGMENT_PROMOTION_TYPE } from "src/segment";
import { PromotionEventType, useSegment } from "src/hooks/useSegment";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";

interface RetroRevivalProps {
  items: BrandData[] | null;
}

const RetroRevival: FC<RetroRevivalProps> = ({ items }) => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();
  const [imageElementWrapperHeight, getImageElementWrapper] = useImageHeight();
  const { segmentPromotionClickedOrViewed } = useSegment();
  const { enableGatedHomepage } = useFeatureFlags();

  const getFlexDirection = (): "column" | "row" => {
    if (isDesktop) {
      return "row";
    } else if (isMobile || (isTablet && !Boolean(items?.length === 1))) {
      return "column";
    } else {
      return "row";
    }
  };

  const handleSegmentEvent = (item: BrandData) => {
    void segmentPromotionClickedOrViewed({
      creative: item.image,
      name: item?.title,
      promotionType: SEGMENT_PROMOTION_TYPE.inspirationalSections,
      eventType: PromotionEventType.click,
    });
  };
  const isUserLoggedIn = useIsLoggedIn();

  const inspirationalItems =
    items &&
    items
      .filter(
        (item) => isUserLoggedIn || (enableGatedHomepage ? !item?.gated : true)
      )
      .map((item: BrandData, index: number) => (
        <Link
          key={index}
          onClick={() => handleSegmentEvent(item)}
          href={item?.link}
        >
          <Card
            image={{ src: item.image, alt: item.title }}
            title={item?.title}
            subTitle={item?.subtitle}
            link={item?.link}
            linkAsText
            promoLabels={item?.labels}
            linkCTA={item?.linkCTA || undefined}
            flexDirection={getFlexDirection()}
            getImageElementWrapper={getImageElementWrapper}
            promotionType={SEGMENT_PROMOTION_TYPE.inspirationalSections}
          />
        </Link>
      ));

  const singleItem = items?.[0];

  return singleItem && items?.length === 1 && isMobile ? (
    <Link href={singleItem?.link}>
      <Card
        image={{ src: singleItem.image, alt: singleItem.title }}
        title={singleItem?.title}
        subTitle={singleItem?.subtitle}
        link={singleItem?.link}
        linkAsText
        promoLabels={singleItem?.labels}
        linkCTA={singleItem?.linkCTA || undefined}
        flexDirection={getFlexDirection()}
        getImageElementWrapper={getImageElementWrapper}
        promotionType={SEGMENT_PROMOTION_TYPE.inspirationalSections}
      />
    </Link>
  ) : (
    <RetroRevivalContainer>
      <RetroRevivalWrapper>
        <Carousel
          items={inspirationalItems as ReactNode[]}
          marginSize="l"
          marginSizeMobile="l"
          slidesDesktop={1}
          slidesTablet={items?.length === 1 ? 1 : 2}
          slidesMobile={1.075}
          imageElementWrapperHeight={imageElementWrapperHeight}
        />
      </RetroRevivalWrapper>
    </RetroRevivalContainer>
  );
};

export default RetroRevival;
