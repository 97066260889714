import { FC } from "react";
import Link from "next/link";
import { Trans } from "@lingui/macro";
import { ChevronRightIcon } from "src/icons/ChevronRightIcon";
import { Heading, Text } from "@otrium/atoms";
import { Box } from "@otrium/core";
import {
  StyledProductCarouselInfoText,
  StyledTitle,
  StyledLink,
} from "./ProductCarouselInfoText.styled";
import { sanitize } from "isomorphic-dompurify";

interface ProductCarouselInfoTextProps {
  linkHref?: string;
  subTitle?: string;
  title?: string;
  viewAllOnClick: () => void;
}

const ProductCarouselInfoText: FC<ProductCarouselInfoTextProps> = ({
  linkHref = "",
  subTitle = "",
  title = "",
  viewAllOnClick,
}) => {
  return (
    <StyledProductCarouselInfoText>
      <Box display={["none", "none", "block", "block"]}>
        <StyledTitle
          as="h3"
          dangerouslySetInnerHTML={{
            __html: sanitize(title),
          }}
        />
        <Text
          lineHeight="24px"
          color={"tone.black"}
          marginBottom={[3]}
          as="p"
          dangerouslySetInnerHTML={{
            __html: sanitize(subTitle),
          }}
        />
        {linkHref ? (
          <Link href={linkHref} passHref legacyBehavior>
            <StyledLink asLink hasUnderline onClick={viewAllOnClick}>
              <Trans>View all</Trans>
            </StyledLink>
          </Link>
        ) : (
          <StyledLink asLink hasUnderline onClick={viewAllOnClick}>
            <Trans>View all</Trans>
          </StyledLink>
        )}
      </Box>

      <Box display={["block", "block", "none", "none"]}>
        {linkHref ? (
          <Link href={linkHref} passHref legacyBehavior>
            <StyledLink asLink icon={<ChevronRightIcon strokeWidth={3} />}>
              <Heading
                variant="h2"
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: sanitize(title),
                }}
              />
            </StyledLink>
          </Link>
        ) : (
          <StyledLink
            asLink
            icon={<ChevronRightIcon strokeWidth={3} />}
            onClick={viewAllOnClick}
          >
            <Heading
              variant="h2"
              as="h2"
              dangerouslySetInnerHTML={{
                __html: sanitize(title),
              }}
            />
          </StyledLink>
        )}
        {subTitle && (
          <Text
            lineHeight="24px"
            color={"tone.black"}
            marginBottom={[3]}
            as="p"
            dangerouslySetInnerHTML={{
              __html: sanitize(subTitle),
            }}
          />
        )}
      </Box>
    </StyledProductCarouselInfoText>
  );
};

export default ProductCarouselInfoText;
