import styled from "@emotion/styled";

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  padding: ${({ theme }) => theme.space.space32};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: ${({ theme }) => theme.space.space8};
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      width: 100%;
      padding-left: ${({ theme }) => theme.space.space32};
      text-align: center;
      font-size: ${({ theme }) => theme.fontSizes.fontSize32};
      font-weight: 700;
      font-family: ${({ theme }) => theme.fonts.heading};
      color: ${({ theme }) => theme.colors.tone.black};

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        text-align: left;
        font-size: ${({ theme }) => theme.fontSizes.fontSize20};
        padding-left: 0;
      }
    }
  }

  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
    gap: ${({ theme }) => theme.space.space24};

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      gap: ${({ theme }) => theme.space.space16};
    }

    button {
      min-width: 100%;
    }
  }
`;
