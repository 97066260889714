import styled from "@emotion/styled";
import { Box, Flex } from "@otrium/core";
import { Heading } from "@otrium/atoms";

const PromotionBannerWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: fit-content;
  cursor: pointer;
`;

const StyledBannerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
`;

const StyledBannerHeading = styled(Heading)`
  font-family: ${({ theme }) => theme.typography.fontFamily.SSP};
`;

interface StyledBannerFakeButtonProps {
  textColor?: string;
}

const StyledBannerFakeButton = styled.div<StyledBannerFakeButtonProps>`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.tone.white};
  color: ${({ theme, textColor }) => textColor ?? theme.colors.tone.black};
  padding: ${({ theme }) => `12px ${theme.space.space32}`};
  margin-bottom: ${({ theme }) => `${theme.space.space8}`};
  font-size: ${({ theme }) => `${theme.fontSizes.fontSize16}`};
  border-radius: 4px;
  max-width: calc(
    ${({ theme }) => `calc(${theme.fontSizes.fontSize48} * 4.5)`}
  );
  height: 48px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 100%;
    max-width: 100%;
  }
`;

const ContentWrapper = styled(Box)`
  width: 100%;
  height: 240px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: ${({ theme }) => `${theme.space.space16} ${theme.space.space24}`};
  }
`;

const StyledBannerTypeTwoWrapper = styled(Flex)`
  flex-direction: row;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: column-reverse;
  }
`;

const StyledBannerTypeTwoImg = styled.img`
  width: 57%;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 100%;
  }
`;

interface Props {
  backgroundColor: string;
}

const StyledBannerTypeTwoHeadingWrapper = styled(Box)<Props>`
  width: 43%;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.space.space16} ${theme.space.space32}`};

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: -119px;
    width: 120px;
    height: 100%;
    background-color: ${({ backgroundColor }) => backgroundColor};
    clip-path: polygon(100% 0, 0 0, 0 100%);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 100%;
    padding: ${({ theme }) =>
      `${theme.space.space16} ${theme.space.space16} ${theme.space.space24}`};
    &::after {
      content: unset;
    }
  }
`;

const StyledBannerTimerHeadingWrapper = styled(Box)<Props>`
  width: calc(50% - 32px);
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.space.space32}`};

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 100%;
    padding: ${({ theme }) =>
      `${theme.space.space16} ${theme.space.space16} ${theme.space.space24}`};
    margin-top: -2px;
  }
`;

const StyledBannerTimerHeading = styled(Heading)`
  font-family: ${({ theme }) => theme.typography.fontFamily.SSP};
`;

const StyledBannerTimerImg = styled.img`
  width: calc(50% + 32px);
  object-fit: cover;
  object-position: left center;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 100%;
  }
`;

const StyledBannerTypeTwoHeading = styled(Heading)`
  font-family: ${({ theme }) => theme.typography.fontFamily.SSP};
`;

const StyledPromoCodeWrapper = styled.div<{
  promoCodeColor: string;
  outlineColor: string;
}>`
  position: relative;
  display: flex;
  color: ${({ promoCodeColor }) => `${promoCodeColor}`};
  /*
  background-image instead of border (dashed) because it lies inside of the element
  it also allows setting the dashoffset
  */
  background-image: ${({ outlineColor }) =>
    `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='${encodeURIComponent(
      outlineColor
    )}FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`};
  border-radius: 10px;
  margin-bottom: ${({ theme }) => `${theme.space.space8}`};
  line-height: 24px;

  max-width: 80%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    max-width: 80%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    max-width: 90%;
  }
`;

const StyledPromoCodeText = styled.span`
  display: block;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  min-width: 92px;
`;

const StyledPromoCodePrompt = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding-left: ${({ theme }) => theme.space.space16};
  font-size: 0.9em;
  visibility: hidden;
`;

const StyledPromoCode = styled.div<{ promoCodeColor: string }>`
  position: relative;
  padding: ${({ theme }) =>
    `12px ${theme.space.space16} 12px ${theme.space.space32}`};

  &:hover {
    > .prompt {
      visibility: visible;
    }

    > .code {
      visibility: hidden;
    }
  }
`;

const StyledPromoCodeWedge = styled.div<{ backgroundColor: string }>`
  position: relative;
  right: -1px;
  width: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 20px;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
`;

const StyledPromoCodeButton = styled.div<{
  textColor: string;
  backgroundColor: string;
}>`
  position: relative;
  padding: ${({ theme }) =>
    `12px ${theme.space.space32} 12px ${theme.space.space16}`};

  background-color: ${({ backgroundColor }) => `${backgroundColor}`};
  color: ${({ textColor }) => `${textColor}`};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

export {
  PromotionBannerWrapper,
  StyledBannerImg,
  StyledBannerFakeButton,
  StyledBannerHeading,
  ContentWrapper,
  StyledBannerTypeTwoWrapper,
  StyledBannerTypeTwoHeading,
  StyledBannerTypeTwoImg,
  StyledBannerTypeTwoHeadingWrapper,
  StyledPromoCodeWrapper,
  StyledPromoCode,
  StyledPromoCodeButton,
  StyledPromoCodeWedge,
  StyledPromoCodeText,
  StyledPromoCodePrompt,
  StyledBannerTimerHeadingWrapper,
  StyledBannerTimerHeading,
  StyledBannerTimerImg,
};
