import {
  ContentWrapper,
  StyledBannerFakeButton,
  StyledBannerHeading,
  StyledBannerImg,
} from "src/molecules/PromotionBanner/PromotionBanner.styled";
import { Text } from "@otrium/atoms";
import { Box, Flex } from "@otrium/core";
import { FC } from "react";
import { PromotionBannerProps } from "./PromotionBannerTypes";

const PromotionBannerTypeOne: FC<PromotionBannerProps> = ({
  bannerImageSrc,
  title,
  description,
  buttonTitle,
  conditionText,
  hideCTAButton,
}) => {
  return (
    <Box sx={{ position: "relative" }}>
      <StyledBannerImg src={bannerImageSrc} />
      <ContentWrapper>
        <Flex
          flexDirection="column"
          alignItems="center"
          mt={["space32", "space32", "12px", "20px"]}
          paddingX={["space16", "space0", "space0", "space0"]}
        >
          <StyledBannerHeading as="h2" variant="h1" color="tone.white">
            {title}
          </StyledBannerHeading>
          {description && (
            <Text
              fontSize={["fontSize18", "fontSize18", "22px"]}
              lineHeight={["24px", "24px", "32px"]}
              textAlign="center"
              color="tone.white"
              mb="space16"
            >
              {description}
            </Text>
          )}
          {!hideCTAButton ? (
            <StyledBannerFakeButton>{buttonTitle}</StyledBannerFakeButton>
          ) : null}
          {conditionText && (
            <Text variant="body.source.regular14" color="tone.white">
              {conditionText}
            </Text>
          )}
        </Flex>
      </ContentWrapper>
    </Box>
  );
};

export default PromotionBannerTypeOne;
