import { Trans } from "@lingui/macro";
import { FC, useState } from "react";
import { Text } from "@otrium/atoms";
import {
  StyledBannerTypeTwoHeading,
  StyledBannerTypeTwoHeadingWrapper,
  StyledBannerTypeTwoImg,
  StyledBannerTypeTwoWrapper,
  StyledPromoCode,
  StyledPromoCodeButton,
  StyledPromoCodeWedge,
  StyledPromoCodeWrapper,
  StyledPromoCodeText,
  StyledPromoCodePrompt,
} from "src/molecules/PromotionBanner/PromotionBanner.styled";
import { PromotionBannerProps } from "./PromotionBannerTypes";

interface PromotionBannerTypeThreeProps extends PromotionBannerProps {
  backgroundColor: string;
  buttonColor: string;
  textColor: string;
  promoCode?: string | null;
  promoCodeColor?: string | null;
}

const PromotionBannerTypeThree: FC<PromotionBannerTypeThreeProps> = ({
  bannerImageSrc,
  title,
  description,
  buttonTitle,
  buttonColor,
  conditionText,
  backgroundColor,
  textColor = "White",
  promoCode,
  promoCodeColor,
  hideCTAButton,
}) => {
  const [copyState, setCopyState] = useState<"pristine" | "copied" | "errored">(
    "pristine"
  );

  const copyPromoCode = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const code = (
      e.currentTarget as HTMLDivElement
    ).querySelector<HTMLSpanElement>(".code")?.textContent;

    try {
      if (code) {
        await navigator.clipboard.writeText(code);
        setCopyState("copied");
      }
    } catch (err) {
      setCopyState("errored");
    }
  };

  return (
    <StyledBannerTypeTwoWrapper
      flexDirection={["column-reverse", "row", "row", "row"]}
    >
      <StyledBannerTypeTwoHeadingWrapper backgroundColor={backgroundColor}>
        <StyledBannerTypeTwoHeading
          as="h2"
          variant="h1"
          color={textColor}
          pb={0}
          fontSize={[4, 4, 4, 5]}
          lineHeight={["40px", "40px", "40px", "64px"]}
        >
          {title}
        </StyledBannerTypeTwoHeading>
        {description && (
          <Text
            as="p"
            fontSize={["fontSize18", "fontSize18", "22px"]}
            lineHeight={["24px", "24px", "32px"]}
            textAlign="center"
            color={textColor}
            mb="space16"
          >
            {description}
          </Text>
        )}

        {promoCode && !hideCTAButton ? (
          <StyledPromoCodeWrapper
            outlineColor={buttonColor ?? "Black"}
            promoCodeColor={promoCodeColor ?? "Black"}
          >
            <StyledPromoCode
              promoCodeColor={promoCodeColor ?? "Black"}
              onClick={(e) => void copyPromoCode(e)}
              onMouseLeave={() => setCopyState("pristine")}
            >
              <StyledPromoCodeText className="code">
                {promoCode}
              </StyledPromoCodeText>
              <StyledPromoCodePrompt className="prompt">
                {copyState === "copied" && <Trans>Copied!</Trans>}
                {copyState === "pristine" && <Trans>Click to copy</Trans>}
                {copyState === "errored" && <Trans>Copying failed</Trans>}
              </StyledPromoCodePrompt>
            </StyledPromoCode>
            <StyledPromoCodeWedge backgroundColor={buttonColor ?? "Black"} />
            <StyledPromoCodeButton
              textColor={backgroundColor}
              backgroundColor={buttonColor ?? "Black"}
            >
              {buttonTitle}
            </StyledPromoCodeButton>
          </StyledPromoCodeWrapper>
        ) : null}

        {conditionText && (
          <Text
            variant="body.source.regular14"
            color={textColor}
            textAlign="center"
          >
            {conditionText}
          </Text>
        )}
      </StyledBannerTypeTwoHeadingWrapper>
      <StyledBannerTypeTwoImg src={bannerImageSrc} />
    </StyledBannerTypeTwoWrapper>
  );
};

export default PromotionBannerTypeThree;
