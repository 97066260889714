import styled from "@emotion/styled";
import { Text, Heading } from "@otrium/atoms";
import { Box, Flex } from "@otrium/core";
import { default as NextImage } from "next/image";
import { Button } from "src/atoms/Button";

export const CardWrapper = styled(Flex)`
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: ${({ flexDirection = "row" }) => flexDirection};
    ${({ flexDirection }) =>
      flexDirection &&
      flexDirection === "column" &&
      `
      flex: 1 0 50%;
    `}
  }
`;

export const CardImageContainer = styled(Box)<{
  flexDirection?: "row" | "row-reverse" | "column";
}>`
  ${({ theme, flexDirection }) =>
    (flexDirection === "row" || flexDirection === "row-reverse") &&
    `
    @media (min-width: ${theme.breakpoints[1]}) {
      flex: 1 0 50%;
      max-width: 50%;
      ${
        flexDirection === "row-reverse"
          ? `padding-left: ${theme.space.space16};`
          : `padding-right: ${theme.space.space16};`
      }
    }
  `}
`;

export const CardImageWrapper = styled(Box)<{
  withHorizontalImage?: boolean;
  hasTimerEnded?: boolean;
}>`
  padding-bottom: ${({ withHorizontalImage }) =>
    withHorizontalImage ? "75%" : "127%"};
  position: relative;
  overflow: hidden;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-bottom: 75%;
  }

  ${({ hasTimerEnded }) => {
    if (hasTimerEnded) {
      return `
      &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.5);
      }`;
    }

    return;
  }}
`;

export const CardImage = styled(NextImage)`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

export const CardContent = styled(Flex)`
  flex-direction: column;
  padding: ${({ theme }) => theme.space.space16};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    align-items: center;
    justify-content: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${({ flexDirection }) =>
      flexDirection !== "column" &&
      `
      flex: 1 0 50%;
      max-width: 50%;
    `}
  }
`;

export const StyledHeading = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  margin-bottom: ${({ theme }) => theme.space.space8};
  color: ${({ theme }) => theme.colors.tone.black};
  text-transform: none;
  font-family: "CharisSILW";
  line-height: 1.33;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
    line-height: 1.25;
  }
`;

export const StyledLink = styled("a")<{
  color?: string;
}>`
  border: none;
  display: flex;
  background-color: transparent;
  color: ${({ theme, color }) => (color ? color : theme.colors.tone.black)};
  cursor: pointer;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-position: under;
  font-weight: 600;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const StyledPromoLabelsContainer = styled(Flex)`
  flex-direction: column-reverse;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const StyledPromoLabelsWrapper = styled(Flex)`
  padding: ${({ theme }) => theme.space.space16};
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-end;
`;

export const StyledPromoLabel = styled(Text)<{
  labelColor?: string;
  textColor?: string;
}>`
  background-color: ${({ labelColor, theme }) =>
    labelColor ? labelColor : theme.colors.tone.sand};
  color: ${({ textColor, theme }) =>
    textColor ? textColor : theme.colors.tone.black};
  color: ${({ theme }) => theme.colors.tone.black};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  margin-right: ${({ theme }) => theme.space.space8};
  margin-top: ${({ theme }) => theme.space.space8};
  padding: 2px ${({ theme }) => theme.space.space8};
  border-radius: 2px;
`;

export const StyledCTAButton = styled(Button)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 100%;
  }
`;
