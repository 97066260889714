import styled from "@emotion/styled";
import { Box } from "@otrium/core";

const StyledProductCarousel = styled.section<{ showInfoText?: boolean }>`
  display: block;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: ${({ showInfoText }) => (showInfoText ? "flex" : "block")};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: ${({ showInfoText }) => (showInfoText ? "flex" : "block")};
  }
`;

const StyledCarouselContainer = styled.div<{ showInfoText?: boolean }>`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: ${({ showInfoText }) => (showInfoText ? "75%" : "100%")};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: ${({ showInfoText }) => (showInfoText ? "83.33%" : "100%")};
  }
`;

const StyledNewInProductCarousel = styled(Box)`
  padding-right: ${({ theme }) => theme.space.space0};
  padding-left: ${({ theme }) => theme.space.space0};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-right: ${({ theme }) => theme.space.space32};
    padding-left: ${({ theme }) => theme.space.space32};

    .swiper-wrapper {
      .swiper-slide {
        &:first-of-type {
          padding-left: ${({ theme }) => theme.space.space0};
        }

        &:last-of-type {
          margin-right: ${({ theme }) => theme.space.space0};
        }
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding-right: ${({ theme }) => theme.space.space0};
    padding-left: ${({ theme }) => theme.space.space0};

    .swiper-wrapper {
      .swiper-slide {
        &:first-of-type {
          padding-left: ${({ theme }) => theme.space.space16};
        }

        &:last-of-type {
          margin-right: ${({ theme }) => theme.space.space16};
        }
      }
    }
  }
`;

export {
  StyledProductCarousel,
  StyledCarouselContainer,
  StyledNewInProductCarousel,
};
