import { FC } from "react";

interface Props {
  needsWrapper: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}

const ConditionalWrapper: FC<Props> = ({
  needsWrapper,
  wrapper,
  children,
}: Props): JSX.Element => (needsWrapper ? wrapper(children) : children);

export default ConditionalWrapper;
