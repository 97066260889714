import { Modal } from "src/atoms/Modal";
import { DialogContainer } from "./FlashSaleEndDialog.styled";
import { Trans } from "@lingui/macro";
import { Button } from "@otrium/atoms";
import { CloseButton } from "src/atoms/CloseButton";
import { useOnClickOutside } from "src/hooks/useOnClickOutside";
import { useRef } from "react";
import { theme } from "src/theme";

function FlashSaleEndDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}): JSX.Element {
  const modalRef = useRef(null);

  useOnClickOutside(modalRef, onClose);

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      centerOnScreen
      isOpen={isOpen}
      onClose={onClose}
      fullHeight={false}
      padding={{
        inlineEnd: theme.space.space16,
        inlineStart: theme.space.space16,
      }}
    >
      <DialogContainer ref={modalRef}>
        <section className="header">
          <span className="title">
            <Trans>Flash sale has ended</Trans>
          </span>
          <CloseButton onClick={handleClose} />
        </section>
        <section className="body">
          <Trans>This sale has ended. New flash sales coming soon.</Trans>
          <Button variant="primary" type="button" onClick={handleClose}>
            <Trans>Continue shopping</Trans>
          </Button>
        </section>
      </DialogContainer>
    </Modal>
  );
}

export { FlashSaleEndDialog };
