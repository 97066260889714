import styled from "@emotion/styled";
import { FC, PropsWithChildren } from "react";
import Link from "next/link";
import { HyperLink } from "src/atoms/HyperLink";
import { Trans } from "@lingui/macro";

interface Props extends PropsWithChildren {
  link?: string;
  onClick: () => void;
  overlayBgColor?: string;
}

const StyledBlurredItem = styled.div<{ bgColor?: string }>`
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  filter: blur(4px);
  background: ${({ bgColor }) => (bgColor ? bgColor : "transparent")};
`;

const StyledLinkContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
`;

const CarouselOverlay: FC<Props> = ({
  children,
  link,
  onClick,
  overlayBgColor,
}) => {
  return (
    <>
      <StyledBlurredItem bgColor={overlayBgColor}>{children}</StyledBlurredItem>
      <StyledLinkContainer>
        {link ? (
          <Link href={link} passHref legacyBehavior>
            <HyperLink hasUnderline fullWidth asLink onClick={onClick}>
              <Trans>View all</Trans>
            </HyperLink>
          </Link>
        ) : (
          <HyperLink hasUnderline fullWidth asLink onClick={onClick}>
            <Trans>View all</Trans>
          </HyperLink>
        )}
      </StyledLinkContainer>
    </>
  );
};

export default CarouselOverlay;
