const ChevronRightIcon = ({ stroke = "#000", strokeWidth = 1, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="16"
    viewBox="0 0 9 16"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    >
      <g stroke={stroke} transform="translate(-16 -13)">
        <g transform="matrix(-1 0 0 1 43 0)">
          <path
            d="M14 0L7 7.73175271 0 0"
            transform="rotate(90 6.143 20.447)"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export { ChevronRightIcon };
