// Deprecated use type instead of the enum
export enum PromotionBannerTypes {
  Type_One = "TYPE_ONE",
  Type_Two = "TYPE_TWO",
  Type_Three = "TYPE_THREE",
  Type_Timer = "FLASH_SALE",
}

export type PromotionBannerType =
  | "TYPE_ONE"
  | "TYPE_TWO"
  | "TYPE_THREE"
  | "FLASH_SALE";
