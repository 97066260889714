import { FC } from "react";
import { PromotionBannerProps } from "./PromotionBannerTypes";
import { Text } from "@otrium/atoms";
import {
  StyledBannerFakeButton,
  StyledBannerTypeTwoHeading,
  StyledBannerTypeTwoHeadingWrapper,
  StyledBannerTypeTwoImg,
  StyledBannerTypeTwoWrapper,
} from "src/molecules/PromotionBanner/PromotionBanner.styled";

interface PromotionBannerTypeTwoProps extends PromotionBannerProps {
  backgroundColor: string;
}

const PromotionBannerTypeTwo: FC<PromotionBannerTypeTwoProps> = ({
  bannerImageSrc,
  title,
  description,
  buttonTitle,
  conditionText,
  backgroundColor,
  hideCTAButton,
}) => {
  return (
    <StyledBannerTypeTwoWrapper
      flexDirection={["column-reverse", "row", "row", "row"]}
    >
      <StyledBannerTypeTwoHeadingWrapper backgroundColor={backgroundColor}>
        <StyledBannerTypeTwoHeading
          as="h2"
          variant="h1"
          color="tone.white"
          pb={0}
          fontSize={[4, 4, 4, 5]}
          lineHeight={["40px", "40px", "40px", "64px"]}
        >
          {title}
        </StyledBannerTypeTwoHeading>
        {description && (
          <Text
            as="p"
            fontSize={["fontSize18", "fontSize18", "22px"]}
            lineHeight={["24px", "24px", "32px"]}
            textAlign="center"
            color="tone.white"
            mb="space16"
          >
            {description}
          </Text>
        )}
        {!hideCTAButton ? (
          <StyledBannerFakeButton>{buttonTitle}</StyledBannerFakeButton>
        ) : null}
        {conditionText && (
          <Text
            variant="body.source.regular14"
            color="tone.white"
            textAlign="center"
          >
            {conditionText}
          </Text>
        )}
      </StyledBannerTypeTwoHeadingWrapper>
      <StyledBannerTypeTwoImg src={bannerImageSrc} />
    </StyledBannerTypeTwoWrapper>
  );
};

export default PromotionBannerTypeTwo;
