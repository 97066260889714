import styled from "@emotion/styled";
import { Container } from "src/atoms/Container";
import { Box } from "@otrium/core";

export const RetroRevivalContainer = styled(Container)`
  @media (min-width: ${({ theme }) =>
      theme.breakpoints[1]}) and (max-width: ${({ theme }) =>
      theme.breakpoints[2]}) {
    padding-right: ${({ theme }) => `${theme.space.space32}`};
    padding-left: ${({ theme }) => `${theme.space.space32}`};
  }

  .swiper-slide {
    &:first-of-type {
      padding-left: ${({ theme }) => theme.space.space0};
    }

    &:last-of-type {
      margin-right: ${({ theme }) => theme.space.space0};
    }
  }
`;

export const RetroRevivalWrapper = styled(Box)`
  margin-inline: ${({ theme }) => theme.space.space0} -${({ theme }) => theme.space.space16};
  padding-block: ${({ theme }) => theme.space.space32};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin-inline: ${({ theme }) => theme.space.space0};
  }

  @media (min-width: ${({ theme }) =>
      theme.breakpoints[1]}) and (max-width: ${({ theme }) =>
      theme.breakpoints[2]}) {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-block: ${({ theme }) => theme.space.space96};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    .swiper-slide {
      &:first-of-type {
        padding-left: ${({ theme }) => theme.space.space0};
      }

      &:last-of-type {
        margin-right: ${({ theme }) => theme.space.space0};
      }
    }
  }
`;
