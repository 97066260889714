import { FC, useEffect, useState } from "react";
import { Container } from "src/atoms/Container";
import { useBreakpoints } from "@otrium/core";
import { PromotionBannerData } from "src/types/graphql.d";
import { PromotionBannerWrapper } from "./PromotionBanner.styled";
import { PromotionBannerTypes } from "src/types/PromotionBannerTypes";
import PromotionBannerTypeOne from "src/molecules/PromotionBanner/PromotionBannerTypeOne";
import PromotionBannerTypeTwo from "src/molecules/PromotionBanner/PromotionBannerTypeTwo";
import PromotionBannerTypeThree from "src/molecules/PromotionBanner/PromotionBannerTypeThree";
import { FlashSaleEndDialog } from "src/molecules/FlashSaleEndDialog";
import { BannerTimer } from "./Variations/BannerTimer";
import { useRouter } from "next/router";

interface PromotionBannerProps {
  promotionBanner: PromotionBannerData;
}

const PromotionBanner: FC<PromotionBannerProps> = ({ promotionBanner }) => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();
  const [bannerImage, setBannerImage] = useState("");
  const [hasTimerEnded, setTimerEnded] = useState(false);
  const [showFlashSaleEndModal, setShowFlashSaleEndModal] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (isMobile) {
      setBannerImage(promotionBanner.mobile_image);
    } else if (isTablet) {
      setBannerImage(promotionBanner.tablet_image);
    } else if (isDesktop) {
      setBannerImage(promotionBanner.desktop_image);
    }
  }, [
    isMobile,
    isTablet,
    isDesktop,
    promotionBanner.mobile_image,
    promotionBanner.tablet_image,
    promotionBanner.desktop_image,
  ]);

  const onTimerEnd = (hasTimerEnded: boolean) => setTimerEnded(hasTimerEnded);

  const showTimerEndModal = () => setShowFlashSaleEndModal(true);
  const hideTimerEndModal = () => {
    router.reload();
    setShowFlashSaleEndModal(false);
  };

  return (
    <Container maxWidth={1440} pl={0} pr={0} pb={0}>
      <PromotionBannerWrapper
        as={promotionBanner.hide_cta_button || hasTimerEnded ? "div" : "a"}
        href={
          hasTimerEnded || promotionBanner.hide_cta_button
            ? undefined
            : (promotionBanner.banner_link as string)
        }
        onClick={
          !promotionBanner.hide_cta_button && hasTimerEnded
            ? showTimerEndModal
            : undefined
        }
      >
        {promotionBanner.type === PromotionBannerTypes.Type_One && (
          <PromotionBannerTypeOne
            bannerImageSrc={bannerImage}
            title={promotionBanner.title}
            description={promotionBanner.description}
            buttonTitle={promotionBanner.button_title}
            conditionText={promotionBanner.condition_text}
            hideCTAButton={promotionBanner.hide_cta_button || false}
          />
        )}
        {promotionBanner.type === PromotionBannerTypes.Type_Two && (
          <PromotionBannerTypeTwo
            bannerImageSrc={bannerImage}
            title={promotionBanner.title}
            buttonTitle={promotionBanner.button_title}
            conditionText={promotionBanner.condition_text}
            description={promotionBanner.description}
            backgroundColor={promotionBanner.background_colour || "tone.black"}
            hideCTAButton={promotionBanner.hide_cta_button || false}
          />
        )}

        {promotionBanner.type === PromotionBannerTypes.Type_Three && (
          <PromotionBannerTypeThree
            bannerImageSrc={bannerImage}
            title={promotionBanner.title}
            buttonTitle={promotionBanner.button_title}
            buttonColor={promotionBanner.button_colour}
            conditionText={promotionBanner.condition_text}
            description={promotionBanner.description}
            backgroundColor={promotionBanner.background_colour || "tone.black"}
            textColor={promotionBanner.text_colour}
            promoCode={promotionBanner.promo_code}
            promoCodeColor={promotionBanner.promo_code_colour}
            hideCTAButton={promotionBanner.hide_cta_button || false}
          />
        )}

        {promotionBanner.type === PromotionBannerTypes.Type_Timer ? (
          <>
            <BannerTimer
              bannerImageSrc={bannerImage}
              title={promotionBanner.title}
              buttonTitle={promotionBanner.button_title}
              conditionText={promotionBanner.condition_text}
              description={promotionBanner.description}
              backgroundColor={
                promotionBanner.background_colour || "tone.black"
              }
              startDate={promotionBanner.start_date || ""}
              endDate={promotionBanner.end_date || ""}
              onTimerEnd={
                !promotionBanner.hide_cta_button ? onTimerEnd : undefined
              }
              hideCTAButton={promotionBanner.hide_cta_button || false}
            />
            {hasTimerEnded ? (
              <FlashSaleEndDialog
                isOpen={showFlashSaleEndModal}
                onClose={hideTimerEndModal}
              />
            ) : null}
          </>
        ) : null}
      </PromotionBannerWrapper>
    </Container>
  );
};

export default PromotionBanner;
