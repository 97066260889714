import { FC } from "react";
import { PromotionBannerProps } from "../PromotionBannerTypes";
import { Text } from "@otrium/atoms";
import {
  StyledBannerFakeButton,
  StyledBannerTimerHeading,
  StyledBannerTimerHeadingWrapper,
  StyledBannerTimerImg,
  StyledBannerTypeTwoWrapper,
} from "src/molecules/PromotionBanner/PromotionBanner.styled";
import { Timer } from "src/atoms/Timer";
import NoSSR from "src/atoms/NoSSR";

interface BannerTimerProps extends PromotionBannerProps {
  startDate: string;
  endDate: string;
  backgroundColor: string;
  onTimerEnd?: (hasTimerEnded: boolean) => void;
  hideCTAButton?: boolean;
}

const BannerTimer: FC<BannerTimerProps> = ({
  bannerImageSrc,
  description,
  buttonTitle,
  conditionText,
  endDate,
  backgroundColor,
  onTimerEnd,
  hideCTAButton,
}) => {
  return (
    <StyledBannerTypeTwoWrapper
      flexDirection={["column-reverse", "row", "row", "row"]}
    >
      <StyledBannerTimerHeadingWrapper backgroundColor={backgroundColor}>
        <StyledBannerTimerHeading
          as="h2"
          variant="h1"
          color="tone.white"
          pb={0}
          fontSize={[4, 4, 4, 5]}
          lineHeight={["40px", "40px", "40px", "64px"]}
        >
          <NoSSR>
            <Timer endDate={endDate} onTimerEnd={onTimerEnd} />
          </NoSSR>
        </StyledBannerTimerHeading>

        {description && (
          <Text
            as="p"
            fontSize={["fontSize18", "fontSize18"]}
            lineHeight={["24px", "24px", "32px"]}
            textAlign="center"
            color="tone.white"
            mb="space16"
          >
            {description}
          </Text>
        )}
        {!hideCTAButton ? (
          <StyledBannerFakeButton textColor={backgroundColor}>
            {buttonTitle}
          </StyledBannerFakeButton>
        ) : null}
        {conditionText && (
          <Text
            variant="body.source.regular14"
            color="tone.white"
            textAlign="center"
          >
            {conditionText}
          </Text>
        )}
      </StyledBannerTimerHeadingWrapper>
      <StyledBannerTimerImg src={bannerImageSrc} />
    </StyledBannerTypeTwoWrapper>
  );
};

export { BannerTimer };
